<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col cols="12" class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden">
      <h2>Daftar Barang</h2>
      <div class="d-flex justify-content-between">
        <div class="row align-items-center">
          <p class="hp-p1-body mb-0 mx-3 text-black">
            Semua ({{ table.total }})
          </p>
          <b-button variant="primary-2" to="/master-data/tambah-daftar-barang">
            Tambah Jenis Barang
          </b-button>
        </div>
        <div class="row no-gutters w-15">
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                variant="outline-black-50"
                class="border-right-0 pr-0 pl-2"
              >
                <i class="ri-search-line remix-icon text-primary" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="table.search"
              type="search"
              placeholder="Search"
              class="border-left-0"
              @input="debounceSearch"
            />
          </b-input-group>
        </div>
      </div>
    </b-col>
    <div class="col-12 mb-3">
      <label>Kategori:</label>
      <v-select
        v-model="table.selectedKategori"
        :options="kategoriOptions"
        placeholder="Pilih Kategori"
        @input="changeKategori"
      />
      <b-table
        style="overflow: auto; white-space: nowrap"
        :items="table.items"
        :fields="table.fields"
        :busy.sync="isBusy"
        responsive="sm"
      >
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Memuat...</strong>
          </div>
        </template>
        <template #cell(aksi)="{ item }">
          <b-button
            variant="info"
            :to="`/master-data/edit-daftar-barang/${item.id}`"
          >
            <i class="ri-edit-box-line mr-1" />
            Edit
          </b-button>
        </template>
      </b-table>
      <b-pagination
        class="mb-3"
        align="right"
        @input="changePage"
        v-model="table.currentPage"
        :per-page="table.perPage"
        :total-rows="table.total"
      />
    </div>
  </b-row>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BSpinner,
  BTable,
  BPagination,
} from "bootstrap-vue";
import tableDataMixins from "@/mixins/tableDataMixins";
import vSelect from "vue-select";
import manageCategoryInventoryAPI from "../../../../api/managecategoryinventory/manageCategoryInventoryAPI";

export default {
  name: "ListTypeInventory",
  components: {
    BSpinner,
    BButtonGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BRow,
    BInputGroup,
    BCol,
    BTable,
    BPagination,
    vSelect,
  },
  mixins: [tableDataMixins],
  data() {
    return {
      table: {
        selectedKategori: "",
        items: [],
        fields: [
          {
            label: "NO",
            key: "no",
            sortable: true,
          },
          {
            label: "Kode Barang",
            key: "item_code",
            sortable: true,
          },
          {
            label: "Nama Barang",
            key: "item_name",
            sortable: true,
          },
          {
            label: "Vendor",
            key: "vendor",
            sortable: true,
          },
          {
            label: "Jumlah Minimum",
            key: "minimum_quantity",
            sortable: true,
          },
          {
            label: "Note",
            key: "description",
            sortable: true,
          },
          {
            label: "Update",
            key: "updated_at",
            sortable: true,
          },
          {
            label: "Aksi",
            key: "aksi",
          },
        ],
      },
      kategoriOptions: [],
    };
  },
  computed: {
    typeInventorys() {
      return this.$store.state.manageTypeInventory.typeInventorys;
    },
    totalType() {
      return this.$store.state.manageTypeInventory.totalPages;
    },
    isBusy() {
      return this.$store.state.manageTypeInventory.isBusy;
    },
  },
  methods: {
    async fetchData() {
      this.table.loading = this.isBusy;
      await this.$store.dispatch("manageTypeInventory/getTypeInventorys", {
        page: this.table.currentPage,
        per_page: this.table.perPage,
        search: this.table.search,
        category: this.table.selectedKategori
          ? this.table.selectedKategori
          : undefined,
      });
      this.table.items = this.typeInventorys;
      this.table.total = this.totalType;
    },

    async getListCategory() {
      const { data: response } = await manageCategoryInventoryAPI.getAll();
      this.kategoriOptions = response.data.data.map(
        (item) => item.category_name
      );
    },

    changeKategori() {
      this.table.selectedKategori =
        this.table.selectedKategori === "Semua"
          ? null
          : this.table.selectedKategori;
      this.fetchData();
    },

    async destroyType(id) {
      let formData = new FormData();

      formData.append("id", id);

      await this.$store
        .dispatch("manageTypeInventory/destroyType", formData)
        .then(() => {
          this.table.items = this.table.items.filter((item) => item.id !== id);
          this.$bvToast.toast("Data Type Inventory Berhasil Di Hapus", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        });
    },
    changePage(page) {
      this.table.currentPage = page;
      this.fetchData();
    },
    debounceSearch() {
      clearTimeout(this.table.debounceTimer);

      this.table.debounceSearch = setTimeout(() => {
        this.onChangeSearchTable();
      }, 500);
    },
    onChangeSearchTable() {
      this.fetchData();
    },
  },
  mounted() {
    this.getListCategory();
    this.fetchData();
  },
};
</script>
